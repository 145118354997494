import React, { Component } from 'react';
import Helmet from 'react-helmet';

import { MDXProvider } from '@mdx-js/react';

import ThemeProvider from '../components/theme/themeProvider';
import mdxComponents from '../components/mdxComponents';

import EventsIcon from '../components/images/icons/events.svg';
import EngineeringIcon from '../components/images/icons/engineering.svg';
import ResourcesIcon from '../components/images/icons/resources.svg';
import AVIcon from '../components/images/icons/av.svg';
import EcomIcon from '../components/images/icons/ecom.svg';
import HwacIcon from '../components/images/icons/hwac.svg';
import MeterIcon from '../components/images/icons/meter.svg';

import { Link } from 'gatsby';

const section1Subtext = {
fontSize: '16px',
fontWeight: 200
}

const section2Wrapper = {
  display: 'flex',
  padding: '2rem',
  paddingBottom: 0,
  background: '#f1f3f4',
  flexDirection: 'column',
  rowGap: '1rem',
};

const section2Header = {
  fontSize: '32px',
  color: '#000',
  textAlign: 'center',
};
const section2Content = {
  display: 'flex',
  columnGap: '3rem',
  flexWrap: 'wrap',
  justifyContent: 'center',
};
const section2Child = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '200px',
  width: '200px',
  textAlign: 'center',
  borderRadius: '10px',
  // border: '1px solid #ccc',
  transition: 'box-shadow .3s',
  rowGap: '0.75rem',
  fontSize: '12px',
  // boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
};

const section2ChildLabel = {
  color: '#000',
  fontSize: '16px',
  textAlign: 'left'
};


const svgComponent = {
  background: "#F76161",
  borderRadius: '50px',
  marginBottom: '0.75rem'
};


export default class MDXRuntimeTest extends Component {
  render() {
    let location = '';
    let events = [ 'Upcoming: Webinar on onboarding express entry APIs']
    return (
      <ThemeProvider location={location}>
        <MDXProvider components={mdxComponents}>
          <Helmet>
          <title>MyGate Developer Portal</title>
          </Helmet>
          <div className="homepage">
            <div className="homepageBannerWrapper">
              <div className="homepageBannerTextContainer"><span>MyGate Engineering</span> <span style={section1Subtext}>The one-stop destination of integrations, events, technical articles and engineering initiatives</span></div>
               <EngineeringIcon></EngineeringIcon>
            </div>

            <div style={section2Wrapper}>
              <div style={section2Header} id="buildWithMyGate">
                Build with MyGate
              </div>
              <div style={section2Content}>
                <div style={section2Child} id="module_widget">
                  <Link to="/expressentry/">
                    <EcomIcon style={svgComponent} />
                    <div style={section2ChildLabel}>Express Entry APIs</div>
                  </Link>
                </div>
                <div style={section2Child} id="module_widget">
                  <Link to="/accesscontrol/">
                  <HwacIcon style={svgComponent} />
                    <div style={section2ChildLabel}>Hardware Access APIs</div>
                  </Link>
                </div>
                <div style={section2Child} id="module_widget">
                  <Link to="/addresswidget/">
                    <AVIcon style={svgComponent} />
                    <div style={section2ChildLabel}>Address APIs</div>
                  </Link>
                </div>
                <div style={section2Child} id="module_widget">
                  <Link to="/utilityprepay/">
                    <MeterIcon style={svgComponent} />
                    <div style={section2ChildLabel}>Prepay APIs</div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="homepageWidgetWrapper">
              <div className="homepageWidgetChild" id="data_widget">
                <div className="homepageWidgetHeading">
                  <div>Events</div>
                  <div className="slidingDiv container">
                  <ol className="gradient-list">   {/*Add marquee class to enable animattion  */}
                    {
                      events.map(event => <li >{event}</li>)
                    }
                  </ol>
                  </div>
                </div>
                <EventsIcon></EventsIcon>
              </div>
              <a className="homepageWidgetChild" id="data_widget" href="https://mygate.com/blog/category/technology/ ">
                <div className="homepageWidgetHeading">
                  <div>Resources</div> <div style={section1Subtext}>Discover posts from our team here.</div>
                </div>
                <ResourcesIcon></ResourcesIcon>
              </a>
            </div>
          </div>
        </MDXProvider>
      </ThemeProvider>
    );
  }
}
